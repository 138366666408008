body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.grid {
  white-space: nowrap;
}

.cat {
  width: 3rem;
  height: 3rem;
  background-image: url("cat.8482d3dd.png");
  background-repeat: no-repeat;
  background-size: 3rem 3rem;
  display: inline-block;
}

.buttons {
  padding-bottom: 1.5rem;
  font-size: 1.5rem;
}

/*# sourceMappingURL=index.7e4f8cf6.css.map */
